<template>
  <highcharts v-if="chartOptions" type="chart" :options="chartOptions" />
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'GroupedStackedBarChart',
  props: {
    options: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  data() {
    return {
      SEC_IN_HOUR: 3600,
      chartOptions: null
    };
  },
  computed: {
    ...mapGetters(['showCompareAnalytics', 'deviceSerialNumToDisplayNameMap'])
  },

  async mounted() {
    this.setChartOptions();
  },
  methods: {
    setChartOptions() {
      const { getXAxisConfig, options } = this;
      const { data, ylabelString, chartHeight, dates } = options;

      this.chartOptions = {
        chart: {
          zoomType: 'xy',
          type: 'xrange',
          height: this.showCompareAnalytics ? '70%' : '50%',

          scrollablePlotArea: {
            opacity: 1,
            minHeight: chartHeight,
            scrollPositionY: 0
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: '' //title,
        },
        xAxis: [
          { ...getXAxisConfig() },
          { ...getXAxisConfig(false), opposite: true }
        ],
        yAxis: {
          title: {
            text: ylabelString
          },
          categories: dates,
          reversed: true
        },
        tooltip: {
          formatter: function() {
            const {
              cycle_start_time,
              cycle_finished_time,
              cycle_time,
              device
            } = this.point.custom;
            const date = dates[this.y];
            const deviceName = this.series.name
            return `<span>Device: </span><b>${deviceName}</b>
                <br/><span>Cycle Time: </span><b>${cycle_time}s</b>
                <br/><span>Cycle Start Time: </span><b>${cycle_start_time}</b>
                <br/><span>Cycle Finish Time: </span><b>${cycle_finished_time}</b>
                <br/><span>Date: </span><b>${date}</b>`;
          }
        },
        legend: {
          verticalAlign: 'top',
          borderWidth: 1,
          itemStyle: {
            fontWeight: 'normal',
            fontSize: '10px'
          },
          borderColor: '#e4e4e4',
          backgroundColor: '#f1f1f1'
        },
        plotOptions: {
          series: {
            turboThreshold: 100000
          }
        },
        series: data
      };
    },

    getXAxisConfig() {
      const { getTimeFromSeconds } = this;
      return {
        type: 'time',
        title: {
          text: this.options.xlabelString
        },
        min: 0,
        max: 24,
        labels: {
          rotation: 45,
          formatter: function() {
            return getTimeFromSeconds(this.value);
          }
        }
      };
    },

    getTimeFromSeconds(value) {
      return new Date(value * 1000 * this.SEC_IN_HOUR)
        .toISOString()
        .substring(11, 19);
    }
  }
};
</script>
