import downloadFile from './downloadFile';
import { useToast } from 'vue-toastification';

const toast = useToast();

export default function(data, filename, fields) {
  if (!data || data?.length === 0) {
    toast.info('No data!');
    return;
  }
  if (!fields) fields = Object.keys(data[0]);
  let csvContent = '';
  csvContent += fields.join(',') + '\n';
  csvContent += data.map((obj) => Object.values(obj).join(',')).join('\n');

  downloadFile(filename, 'csv', csvContent);
}
