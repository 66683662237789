<template>
  <highcharts v-if="chartOptions" type="chart" :options="chartOptions" />
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  inject: ['redirectMissedToTrace'],
  props: {
    options: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    chartIndex: {
      type: Number,
      default: -1,
      required: true,
    },
  },
  data() {
    return {
      chartOptions: null,
    };
  },

  computed: {
    ...mapGetters(['taskProcesses']),
  },

  mounted() {
    this.setChartOptions();
  },

  watch: {
    options: {
      handler(val) {
        this.setChartOptions();
      },
      deep: true,
    },
  },

  methods: {
    getMissedStepIndex(label) {
      let subSteps = [];
      this.taskProcesses.forEach((process) => {
        const processSubSteps = process.steps.reduce(
          (res, el) => [...res, ...el.substeps],
          []
        );
        subSteps = [...subSteps, ...processSubSteps];
      });
      return subSteps.indexOf(label);
    },
    setChartOptions() {
      const { data, labels, xlabelString, ylabelString } = this.options;
      const self = this;
      this.chartOptions = {
        chart: {
          zoomType: 'xy',
          height: '70%',
          scrollablePlotArea: {
            minHeight: labels.length * 60,
            scrollPositionY: 0,
          },
          style: {
            padding: '0.5em',
          },
        },
        credits: {
          enabled: false,
        },
        title: {
          text: '', //title,
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: labels,
          title: {
            text: xlabelString,
          },
        },

        yAxis: {
          title: {
            text: ylabelString,
          },
        },
        tooltip: {
          formatter: function () {
            const { x, y } = this;
            return `<b>${x}</b> missed in <b>${y}</b> cycle(s).`;
          },
        },

        series: [
          {
            type: 'bar',
            data: data,
            cursor: 'pointer',
            point: {
              events: {
                click: function (e) {
                  self.redirectMissedToTrace({
                    missedSteps: [self.getMissedStepIndex(e?.point?.category)],
                    chartIndex: self.chartIndex,
                  });
                },
              },
            },
          },
        ],
      };
    },
  },
};
</script>
